<template>
      <v-container
        id="street-lighting"
        fluid
        tag="section"
    >
        <v-alert
      dense
      text
      type="success"
      dismissible
      v-model="alert"
    >
      Section I <strong>saved</strong> successfully.
    </v-alert>
        <v-row justify="center">
            <v-col
            cols="12"
            md="12"
            >
              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section I: Notice for Visiting Outside Vendors
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12">
                              <p>When meeting outside vendors homeowners need to be aware that one of the Boulder Contracting contractors has established this outlet for convenience of viewing optional products while making their selections of materials for their new home.</p>
                              <br/>
                              <p>Normally there is a process where selected items are placed on a “Customer Bid” form and then sent to the contractor to apply any additional installation charges that may apply for that specific item. The contractor will then forward the “Customer Bid” form to Boulder Contracting to discuss with the homeowner. This process may take a few days to complete due to the contractors work load working with other homeowners. Changes thereafter are allowed by the homeowner, if pricing of upgraded items does not work into their budget.</p>
                              <br/>
                                <p>Once the homeowner has reviewed and accepted ALL the vendor’s “Customer Bid” form, Boulder Contracting will draft a “Customer Change” form which then adds these items to the homeowners Page #2 price sheet of the Purchase Agreement.</p>
                              <br/>
                              <p>Once all the vendor bids have been approved by our homeowner & the electrical walkthrough meeting has been conducted, Boulder Contracting will provide homeowner with an updated Page 2 price sheet, prior to this time frame - it will be the homeowner’s respon-sibility to keep track of their upgrade totals with our vendors.</p>
                            </v-col>
                          </v-row>
                      <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      ></v-dialog>
                     </v-container>
                </v-form>
                </base-material-card>
              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Signature
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                         <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 1"
                                    v-model="section.signiture_home_owner1"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.date1"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 2"
                                    v-model="section.signiture_home_owner2"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.date2"/>
                                </v-col>
                         </v-row>
                      <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection()">Sign</v-btn>
                    </v-col>
                             </v-container>
                          </v-form>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import axios from "axios";

export default {
  name: 'sectionF',
  data() {
    return {
      home_id: localStorage.getItem("homeid"),
      alert:false,
      dialog: null,
      valid: null,
      staff_email: 'steve@skelleymarketing.com',
      section_id: "F",
      add_vendor_email:'',
        vendor_email: [],
        selected_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
        default_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
      section: {
        home_owner: "",
        property_address: "",
        signiture_home_owner1: "",
        date1: "",
        signiture_home_owner2: "",
        date2: ""
      },
    }
  },
  created () {
    this.getSection()
    this.getVendorEmail()
    },
    methods:{
      getSection(){
            axios
            .get(process.env.VUE_APP_GET_SECTION_URL,
            {
            params: {
                home_id: this.home_id,
                section_id: this.section_id
            }
            })
            .then(response => {
                if (response.data)
                {
                    var result = response.data
                    this.section = JSON.parse(result['JsonDetails'])
                }
            })
            .catch(error => {
            console.log(error)
            })
      },
      getVendorEmail(){
          axios.get(process.env.VUE_APP_GET_VENDOR_EMAIL_URL,{})
          .then(response => {
            this.vendor_email = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      updateSection(){
          var body ={
            'home_id':this.home_id,
            'section_id': this.section_id,
            "section":JSON.stringify(this.section),
          }
          axios
          .post(process.env.VUE_APP_UPDATE_SECTION_URL,body)
          .then(response => {
              console.log(response.data)
              this.alert = true
          })
          .catch(error => {
          console.log(error)
          })
      },
      sectionComplete(){
          var body ={
            'home_id':this.home_id,
            'section_id': this.section_id,
            "section":JSON.stringify(this.section),
          }
          axios
          .post(process.env.VUE_APP_UPDATE_SECTION_TWO_URL,body)
          .then(response => {
              console.log(response.data)
              this.alert = true
              this.sendEmail([this.homeowner_emailid],this.staff_email,"home_section_submit_notification",this.selected_user.PrimaryFirstName, this.section_id)
          })
          .catch(error => {
          console.log(error)
          })
          this.add_vendor_email = Object.assign({}, this.add_vendor_email)
          this.editedIndex = -1
          this.close()
      },
      close(){
          this.dialog = false
          this.$nextTick(() => {
            this.add_vendor_email = Object.assign({}, this.add_vendor_email)
            this.editedIndex = -1
          })
      },
      sendEmail(to,from,template,content,section){
        var email_body ={
          "to": to,
          "from": from,
          "template_name": template,
          "template_data": {
            "content": content,
            "section": section
          }
        }
        axios
        .post(process.env.VUE_APP_SEND_EMAIL_URL,email_body)
        .then(response => {
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
        })

      }
    },
    computed: {
      homeowner_emailid() {
        return this.selected_user.PrimaryEmailAddress
      },
      permissions() {
            return localStorage.getItem('permissions')
      },
    }
}
</script>